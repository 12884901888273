

const Controller = function(update) {

    var socket;
    window.addEventListener("gamepadconnected", function(e) {
        var gp = navigator.getGamepads()[e.gamepad.index];
        console.log("Gamepad connected at index %d: %s. %d buttons, %d axes.",
            gp.index, gp.id,
            gp.buttons.length, gp.axes.length);
        setInterval(() => {
            monitorController()
        }, 10)
      });
    
    function monitorController() {
        var gp = navigator.getGamepads()[0]
        var axes = gp.axes.map(ax => parseInt(2 ** 15 * ax))
        if (axes.length < 8) {
            axes = axes.concat(new Array(8 - axes.length).fill(0))
        }
        var cleanButtons = gp.buttons.map(bt => bt.pressed ? 1 : 0);
        if (cleanButtons.length < 16) {
            cleanButtons = cleanButtons.concat(new Array(16 - cleanButtons.length).fill(0))
        }
        var buttons = 0
        for (var b in cleanButtons) {
            if (cleanButtons[b]) 
                buttons = buttons | (1 << b);
        }
        update({axes: axes, buttons: buttons});
    }
}

export default Controller;