import React, { useState, useEffect } from "react";
import Client from "./janus/client.js"
import Api from "./fromPilot/api/api.js"
import Controller from "./fromPilot/controller/controller.js"
import { Socket, DEFAULT_SOCKET_PORT, SOCKET_STATES } from "./fromPilot/driveu/socket.js"
import Stream from './fromPilot/components/Stream.js';

var socket = Socket();


const Drive = () => {
    const [url, setUrl] = useState(null);
    const [streams, setStreams] = useState([]);
    const [transmitPort, setTramsmitPort] = useState(DEFAULT_SOCKET_PORT);
    const [socketState, setSocketState] = useState(SOCKET_STATES.disconnected);
    const [joystickData, setjoystickData] = useState(null);
    const [metaData, setMetaData] = useState(null);

    useEffect(() => {
        async function initJanus() {
            const api = Api()
            const url = await api.getJanusUrl()
            setUrl(url)
            var janus = new Client(url)
            janus.subscribe("attached", () => {
                janus.updateStreamsList()
            });
            janus.subscribe("streams", (streams) => {
                setStreams(streams)
            });

            janus.startClient();
            Controller(setjoystickData);
        }
        initJanus()
    }, []);

    useEffect(() => {
        if (socket.isConnected())
            socket.send({ type: "control", state: joystickData });
    }, [joystickData]);

    function updatePortValue(event) {
        event.target.value ? setTramsmitPort(event.target.value) : setTramsmitPort(DEFAULT_SOCKET_PORT);
    };

    function updateSocketState(state) {
        console.log(state)
        setSocketState(state);
    };

    function connect() {
        socket.connect(transmitPort, updateSocketState, setMetaData);
    };

    return (
        <div className="App">
            <div className="vCenterItems">
                {
                    streams.map((stream) =>{
                        return <Stream key={stream.id} stream={stream} url={url}/>
                    })
                }
            </div>
            <br></br>
            {
                streams.length === 0 ?
                    null :
                    <div className="vCenterItems">
                        <div className="hCenterItems2" style={{minHeight: "145px"}}>
                            <b>Control</b>
                            <div className="hCenterItems">
                                <div style={{color: joystickData ? "#37CE37" : "red"}}>{joystickData ? ((socket.isConnected() ? "Transmitting data" : "Joystick detected")) : "Joystick not detected"}</div>
                                {
                                    joystickData ?
                                        <div className="hCenterItems">
                                            <div>
                                                <b>{joystickData.axes.toString()}</b>
                                            </div>
                                            <div>
                                                <b>{joystickData.buttons.toString(2)}</b>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className="hCenterItems2" style={{minHeight: "145px"}}>
                            <b>Socket</b>
                            <b style={{color: socket.isConnected() ? "#37CE37" : "red"}}>{socketState}</b>
                            {
                                socket.isConnected() ?
                                    <button variant="danger" onClick={socket.disconnect}>Disconnect</button>
                                    :
                                    <div className="hCenterItems">
                                        {
                                            window.location.hostname === "localhost" ?
                                                <div>
                                                    <b>Port</b>
                                                    <input type="number" min="10" max="99999" placeholder={DEFAULT_SOCKET_PORT} onChange={updatePortValue} style={{margin: "10px", width: "100px"}}/>
                                                </div>
                                                :
                                                null
                                        }
                                        <button variant="success" onClick={connect}>Connect</button>
                                    </div>
                            }
                        </div>
                        <div className="hCenterItems2" style={{minHeight: "145px"}}>
                            <b>Metrics</b>
                            <div className="hCenterItems">
                                <div className="vCenterItems">
                                    <div>Battery:</div>
                                    <div>{metaData ? metaData.battery : "N/A"}</div>
                                </div>
                                <div>
                                    <b>Motor temp:</b>
                                    <b>{metaData ? metaData.mtemp : "N/A"}</b>
                                </div>
                                <div>
                                    <b>Speed:</b>
                                    <b>{metaData ? metaData.speed : "N/A"}</b>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
};
export default Drive;
