import React from 'react';
import Box from '@mui/material/Box';


const OperatePage = ({ children }) => {
    return (
        <Box sx ={{width:"100%", height:"100%"}} style={{backgroundColor:"black",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: -99}}>
            { children }
        </Box>
    );
};
export default OperatePage;