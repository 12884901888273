import React, { useState, useEffect } from 'react';
import Client from "../../janus/client.js"
import NodeApi from "../driveu/node_api.js"

const Stream = ({stream, url}) => {

    var node = stream.description.split("@")[1]
    const api = NodeApi(node)

    const [age, setAge] = useState(stream.video_age_ms)
    const [latency, setLatency] = useState(9999)
    const [loss, setLoss] = useState(null)
    const [debug, setDebug] = useState(false)

    function isStreamLive(age){
        return age < 100
    }

    function updateDebug(){
        setDebug(!debug)
    }

    useEffect(() => {
        var janus = new Client(url)
        async function echoForLatency() {
            var lat = await api.echoTwoWay();
            setLatency(lat)
        }
        janus.subscribe("slow_link", (info) => {
            setLoss(info)
        });
        janus.subscribe("attached", () => {
            janus.startStream(stream)
            setInterval(() => janus.getStreamInfo(stream.id), 2000)
        });
        janus.subscribe("stream_info", (info) => {
            setAge(info.video_age_ms)
            if (isStreamLive(info.video_age_ms))
                echoForLatency()
        });
        janus.startClient()
    }, []);



    return (
        <div>
            <video key={stream.id} id={"remotevideo" + stream.id} controls playsInline autoPlay muted width="768" height="432"></video>
            <div className="vCenterItems">
                {isStreamLive(age) ? <b style={{color: "#37CE37"}} onClick={updateDebug}>ONLINE</b> : <b style={{color: "red"}}>OFFLINE</b>}
                {isStreamLive(age) ? <b style={{color: "#37CE37", marginLeft: "5px"}}>| RTC rtt latency: ~{latency} ms</b> : null}
                {isStreamLive(age) && loss && debug ? <b style={{color: "#37CE37", marginLeft: "5px"}}>| {loss.uplink ? "Uplink" : "Downlink"} loss: ~{loss.lost} pk</b> : null}
            </div>
            <div className="vCenterItems">
                <div className="hCenterItems">
                    <b>Video</b>
                    <div className="vCenterItems">
                        <button className="controlButton" variant="secondary" onClick={api.resumeVideo}>resume</button>
                        <button className="controlButton" variant="secondary" onClick={api.pauseVideo}>pause</button>
                        <button className="controlButton" variant="secondary" onClick={api.forceIdr}>IDR</button>
                    </div>
                </div>
                <div className="hCenterItems">
                    <b>Layouts</b>
                    <div className="vCenterItems">
                        <button className="controlButton" variant="secondary" onClick={api.cycleLayoutLeft}>cycle left</button>
                        <button className="controlButton" variant="secondary" onClick={api.cycleLayoutRight}>cycle right</button>
                    </div>
                </div>
                <div className="hCenterItems">
                    <b>Bandwidth</b>
                    <div className="vCenterItems">
                        <button className="controlButton" variant="secondary" onClick={() => api.setBandwidth(1000)}>max bw 1000</button>
                        <button className="controlButton" variant="secondary" onClick={() => api.setBandwidth(2500)}>max bw 2500</button>
                    </div>
                </div>
            </div>
            <div className="vCenterItems">
                <div className="hCenterItems">
                    <b>Post Processing</b>
                    <div className="vCenterItems">
                        <div className="hCenterItems">
                            <button className="controlButton" variant="secondary" onClick={api.increaseSaturation}>saturation up</button>
                            <button className="controlButton" variant="secondary" onClick={api.decreaseSaturation}>saturation down</button>
                        </div>
                        <div className="hCenterItems">
                            <button className="controlButton" variant="secondary" onClick={api.increaseContrast}>contrast up</button>
                            <button className="controlButton" variant="secondary" onClick={api.decreaseContrast}>contrast down</button>
                        </div>
                        <div className="hCenterItems">
                            <button className="controlButton" variant="secondary" onClick={api.increaseBrightness}>brightness up</button>
                            <button className="controlButton" variant="secondary" onClick={api.decreaseBrightness}>brightness down</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="vCenterItems">
                <div className="hCenterItems">
                    <b>Overlay</b>
                    <div className="vCenterItems">
                        <button className="controlButton" variant="secondary" onClick={() => api.overlayOne()}>1</button>
                        <button className="controlButton" variant="secondary" onClick={() => api.overlayTwo()}>2</button>
                        <button className="controlButton" variant="secondary" onClick={() => api.overlayThree()}>3</button>
                        <button className="controlButton" variant="secondary" onClick={() => api.overlayFour()}>4</button>
                    </div>
                </div>
                <div className="hCenterItems">
                    <b>Latency</b>
                    <div className="vCenterItems">
                        <button className="controlButton" variant="secondary" onClick={api.setLowLatencyMode}>low</button>
                        <button className="controlButton" variant="secondary" onClick={api.setHighLatencyMode}>high</button>
                    </div>
                </div>
            </div>
        </div>
      );
};

export default Stream;
