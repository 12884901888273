import React from 'react';
import Box from '@mui/material/Box';


const AdminPage = ({ children }) => {
    return (
        <Box sx ={{width:"100%", height:"100%"}} style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'}}>
            { children }
        </Box>
    );
};
export default AdminPage;