import React from 'react';
import Box from '@mui/material/Box';

const Info = ({label, value}) => {
    return (
        <div style={{marginTop:20}}>
            {`${label}`}
            <br></br>
            {value? ` ${value}` : ' N/A'}
        </div>
    )
}

function StreamInfo({ metaData, playingAge }) {
    return (
        <div style={{
            position: 'fixed',
            bottom: 0,
            width:"100%",
            height: 100
        }}>
            <div style={{zIndex:-99, position: "absolute", left:0, height:"100%", width:"100%", opacity:0.5, backgroundColor:"black"}}/>
            <div style={{ zIndex:999, color:"white"}}>
                <Box  display="flex" justifyContent="space-evenly" alignItems="center">
                    <Info label='Speed' value = {metaData.speed} />
                    <Info label='Battery' value = {metaData.battery} />
                    <Info label='Motor Temperature' value = {metaData.temperature} />
                </Box>
            </div>
        </div>
    );
};

export default StreamInfo;
