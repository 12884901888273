import './App.css';
import {
    BrowserRouter as Router,
    Route,
} from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Login from './Components/Login';
import Vehicles from './Components/Vehicles';
import DriveLegacy from './DriveLegacy';
import Drive from './Components/Drive';
import Logout from './Components/Logout';
import View from './Components/View';
import ApiProvider from './Components/ApiProvider';
import GamepadProvider from './Components/GamepadProvider';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { matchPath } from 'react-router-dom';

const routes = [
    { path: "/" },
    { path: "/addvehicle" },
    { path:"/:vin/edit" },
    { path: "/login" },
    { path: "/logout" },
    { path: "/driveLegacy" },
    { path: "/drive/:hostId" },
    { path: "/view/:hostId"}
]


function App() {
    const history = createBrowserHistory();
    Sentry.init({
        dsn: "https://e54f741799894e01a5661e9bad3da918@o1242331.ingest.sentry.io/6396819",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, matchPath),
            }),
        ],
    });
    return (
        <div className="App">
            <Router>
                <ApiProvider>
                    <GamepadProvider >
                        <Route exact path="/" component= { withAuthenticationRequired(Vehicles) }/>
                        <Route path = "/login" component = {Login}/>
                        <Route path = "/logout" component = {Logout}/>
                        <Route path = "/driveLegacy" component = {DriveLegacy}/>
                        <Route path = "/drive/:hostId" component = {Drive}/>
                        <Route path = "/view/:hostId" component = {View}/>
                    </GamepadProvider>
                </ApiProvider>
            </Router>
        </div>
    );
}

export default App;
