import React from 'react';
import TopBar from './TopBar'
import useVehicles from '../hooks/useVehicles'
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import Actions from './Actions';
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import ContainerLayout from "./ContainerLayout";

const Vehicles = () => {

    const {searchVehicles, vehicles, refreshVehicles} = useVehicles()
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false)

    const columns = [
        {field: 'actions', headerName: "Actions", renderCell: Actions, minWidth: 180},
        {field: 'vin', headerName: "VIN", minWidth: 50},
        {field: 'streamer_uuid', headerName: "Streamer Uuid", minWidth: 200},
        {field: 'streamer_status', headerName: "Streamer Status", minWidth: 200},
        {field: 'relay_uuid', headerName: "Relay Uuid", minWidth: 250},
        {field: 'relay_status', headerName: "Relay Status", minWidth: 200},
        {field: 'node_uuid', headerName: "Node Uuid", minWidth: 250},
        {field: 'node_status', headerName: "Node Status", minWidth: 200},
        {field: 'streamer_version', headerName: "Version", minWidth: 200},
    ]

    const dataGridStyle = {
        borderRadius: 0,
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: 'none',
    }

    async function refresh() {
        setIsLoading(true)
        await refreshVehicles()
        setIsLoading(false)
    }

    async function handleSearch(event) {
        const search = event.target.value
        setSearch(search)
        searchVehicles(search)
    }

    function addRefresh(vehicles) {
        return vehicles.map((vehicle) => {
            return{
                ...vehicle,
                refreshVehicles
            }
        })
    }

    if (!vehicles) {
        return null
    }

    return (
            <ContainerLayout>
                <Stack style={{height: "100%"}} direction={'column'} justifyContent={"space-evenly"}>
                    <TopBar refreshVehicles={refresh}/>
                    <Stack style={{paddingLeft: 20, paddingRight: 10 }} direction={'row'} spacing={5} justifyContent={'space-between'}>
                        <TextField label="Search By VIN" value={search} onChange={handleSearch} variant="standard" style={{paddingBottom: 5}} />
                    </Stack>
                    <div style={{ flexGrow: 1, height: "100%" }}>
                        {vehicles && <DataGrid
                            disableVirtualization
                            components = {{
                                Pagination: null
                            }}
                            style = { dataGridStyle }
                            columns = { columns }
                            rows = { isLoading ? [] : addRefresh(vehicles) }
                            disableColumnMenu
                            rowsPerPageOptions={[]}
                            hideFooter={true}
                        />}
                    </div>
                </Stack>
            </ContainerLayout>
    );
};
export default Vehicles;
