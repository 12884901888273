import {useEffect, useState} from 'react';
import AdminPage from './AdminPage.js'
import AdminPageContent from './AdminPageContent.js'
import { useAuth0 } from "@auth0/auth0-react";

const Logout = () => {
    const { logout } = useAuth0();
    useEffect(() => {
        logout()
    })
    return (
        <AdminPage>
            <AdminPageContent style={{
                padding:30,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'}}>
                <h1>Logging out...</h1>
            </AdminPageContent>
        </AdminPage>
    );
};
export default Logout;

