import React from 'react';
import Paper from '@mui/material/Paper';


const AdminPageContent = ({ children, style }) => {
    return (
        <Paper variant="outlined" sx={{ height:"90%", width: "80%", display: 'flex', flexDirection: 'column' }} style={style}>
            { children }
        </Paper>
    );
};
export default AdminPageContent;
