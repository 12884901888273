
const Api = function() {

    const api = {}

    api.getJanusUrl = async function() {
        return 'https://driveu-rtc1.driveu.auto/janus/'
    }

    return api
}

export default Api;
