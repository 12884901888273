import React from 'react';
import Stack from '@mui/material/Stack';

import CocoLogo from '../svg/coco-logo.svg';
import RoundedButton from './RoundedButton'
import AdminPage from './AdminPage'
import { useAuth0 } from "@auth0/auth0-react";
import AdminPageContent from "./AdminPageContent";

const Login = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <AdminPage>
            <AdminPageContent
                style={{
                    width: 600,
                    height: 800,
                    padding:30,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <form onSubmit={() => loginWithRedirect()}>
                    <Stack spacing={5} style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'}}>
                        <img alt={'Coco'} src={CocoLogo} />
                        <RoundedButton text="Login" onClick={() => loginWithRedirect()} type="submit"/>
                    </Stack>
                </form>
            </AdminPageContent>
        </AdminPage>
    );
}

export default Login;
