import {useEffect, useState} from "react"
import fetchVehicles from "../api/fetchVehicles";
import useFetch from "./useFetch";
import fetchPipelines from "../api/fetchPipelines";

function useVehicles () {
    const [originalFullVehicles, setOriginalFullVehicles] = useState([])
    const [fullVehicles, setFullVehicles] = useState([]);
    const fetch = useFetch()

    async function refreshVehicles() {
        try {
            const vehiclesResponse = await fetchVehicles(fetch)
            const vehicles = await vehiclesResponse.json()
            const pipelineResponse = await fetchPipelines(fetch)
            const pipelines = await pipelineResponse.json()
            const validVehicles = vehicles.filter((vehicle) => vehicle.vin)
            const union = validVehicles.map((vehicle) => {
                const matchingPipeline = pipelines.find((pipeline) => pipeline.vin && pipeline.vin === vehicle.vin)
                return {
                    ...vehicle,
                    ...matchingPipeline,
                    id: vehicle.vin,
                }
            })
            setOriginalFullVehicles(union)
            setFullVehicles(union)
        } catch (err) {
            console.error(err)
        }
    }

    function searchVehicles(search) {
        setFullVehicles(originalFullVehicles.filter((vehicle) => {
            return vehicle.vin.toLowerCase().includes(search.toLowerCase())
        }))
    }

    useEffect(() => {
        refreshVehicles()
    }, [])
    return {searchVehicles ,vehicles: fullVehicles, refreshVehicles} ;
}
export default useVehicles
