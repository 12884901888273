import {apiUrl} from "../definitions";

export default async function assignStreamer(fetch, uuid, rtc){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uuid, rtc }),
    };
    return await fetch(`${apiUrl}/fms/assign`, requestOptions);
}
