
export const DEFAULT_SOCKET_PORT = 9123;

export const SOCKET_STATES = { disconnected: "Disconnected", conncted: "Connected", error: "Error" }

export const Socket = function() {

    var api = {}
    var socket;

    api.connect = function(port=DEFAULT_SOCKET_PORT, stateCallback, metaCallback) {
        var connectionString = window.location.hostname === "localhost" ? 
            "ws://" +  window.location.hostname + ":" + port : 
            "wss://" + window.location.hostname + "/socket/";
        socket = new WebSocket(connectionString);
        socket.onopen = function (event) {
            stateCallback(SOCKET_STATES.conncted);
        };
        socket.onclose = function(event) {
            socket = null;
            stateCallback(SOCKET_STATES.disconnected);
        };

        socket.onerror = function(event) {
            socket = null;
            stateCallback(SOCKET_STATES.error);
        };

        socket.onmessage = function(message){
            console.log(message);
            metaCallback(JSON.parse(message.data));
        }
    };

    api.disconnect = function() {
        socket.close();
    };

    api.send = function(data) {
        if (socket)
            socket.send(JSON.stringify(data));
    };

    api.isConnected = function() {
        return socket && socket.readyState === WebSocket.OPEN
    }

    return api
};
