import DriveEtaIcon from '@mui/icons-material/DriveEta';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MonitorIcon from '@mui/icons-material/Monitor';
import { Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Link from './Link';
import assignStreamer from "../api/assignStreamer";
import useFetch from "../hooks/useFetch";

export default function Actions({ row }) {
    const fetch = useFetch()
    const {
        vin,
        rtc_https_url,
        node_https_url,
        node_wss_url,
        server_ip,
        trampoline_connection_port,
        control_connection_port,
        trampoline_password,
        control_password,
        relay_uuid,
        streamer_uuid,
        refreshVehicles
    } = row
    const urlParams = (rtc_https_url && node_https_url && node_wss_url) ? `?rtc_url=${rtc_https_url}&node_url=${node_https_url}&node_socket_url=${node_wss_url}&vin=${vin}` : '';

    async function handleOpenNode(event) {
        if(!relay_uuid) {
            const response = await assignStreamer(fetch, streamer_uuid, true)
            const newRelay = await response.json()
            await refreshVehicles()
        }
        window.open(`driveunode://?${server_ip}?${trampoline_connection_port}?${control_connection_port}?${trampoline_password}?${control_password}`, "_blank")
        event.preventDefault()
    }

    return <Stack direction={{ xs: 'row' }} spacing={1}>
        <Link to={`/drive/${vin}${urlParams}`} >
            <Tooltip title="Drive">
                <IconButton color="primary">
                    <DriveEtaIcon/>
                </IconButton>
            </Tooltip>
        </Link>
        <Link to={`/view/${vin}${urlParams}`} >
            <Tooltip title="View">
                <IconButton color="primary">
                    <VisibilityIcon/>
                </IconButton>
            </Tooltip>
        </Link>
        <Tooltip title="Node">
            <IconButton  onClick={ handleOpenNode } color="primary">
                <MonitorIcon/>
            </IconButton>
        </Tooltip>
    </Stack>;
}
