import { useState } from "react";
import { useHistory } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import FetchContext from '../context/fetch'
import ErrorPage from "./ErrorPage";
import { useAuth0 } from "@auth0/auth0-react";

function ApiProvider({ children }) {
    const history = useHistory();
    const { logout, getAccessTokenSilently, isLoading } = useAuth0();
    const [error, setError] = useState(null);

    async function getToken() {
        try {
            const token = await getAccessTokenSilently({
                audience: window.authAudience,
                scope: 'openid profile email',
            });
            return token
        } catch (e) {
            history.push('/login');
        }
    }
    async function wrappedFetch(url, options = {}) {
        try {
            const token = await getToken();
            if (!token) {
                return
            }
            const response = await fetch(url, {
                ...options,
                headers: {
                    ...(options.headers || {}),
                    Authorization: `Bearer ${token}`,
                }
            });
            switch (response.status) {
                case 401: {
                    return response;
                }
                case 500: {
                    setError({message:"Unknown Error", code: 500})
                    return null
                }
                default: {
                    setError(null)
                    // do nothing
                }
            }
            return response;
        } catch (e) {
            console.error(e)
            setError({message: 'Unknown Error', code: ''})
            return null
        }
    }

    if (isLoading) {
        return <LinearProgress variant={'indeterminate'} />
    }
    if(error) {
        return <ErrorPage error={error.message} code={error.code} refresh={() => {
            logout({ returnTo: window.location.origin });
        }}/>
    }
    return (<FetchContext.Provider value={wrappedFetch}>
        { children }
    </FetchContext.Provider>)
}

export default ApiProvider
