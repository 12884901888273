
const SPECIAL_BASE = 1000 * 1000 * 1000


const NodeApi = function(url) {

    const client = {}
    const responseHandler = (response) => {
        if(response.status != 200)
            console.log(`Error response from rest api ${response}`)
    };

    client.overlayOne = function() {
        fetch(`${url}/api/keycode/49/d`)
        .then(responseHandler)
    }

    client.overlayTwo = function() {
        fetch(`${url}/api/keycode/50/d`)
        .then(responseHandler)
    }

    client.overlayThree = function() {
        fetch(`${url}/api/keycode/51/d`)
        .then(responseHandler)
    }

    client.overlayFour = function() {
        fetch(`${url}/api/keycode/52/d`)
        .then(responseHandler)
    }

    client.pauseVideo = function() {
        fetch(`${url}/api/keycode/88/d`)
        .then(responseHandler)
    }

    client.resumeVideo = function() {
        fetch(`${url}/api/keycode/90/d`)
        .then(responseHandler)
    }

    client.forceIdr = function() {
        fetch(`${url}/api/keycode/70/d`)
        .then(responseHandler)
    }

    client.increaseSaturation = function() {
        fetch(`${url}/api/keycode/${parseInt(SPECIAL_BASE + parseInt("0x006C"))}/d`)
        .then(responseHandler)
    }

    client.decreaseSaturation = function() {
        fetch(`${url}/api/keycode/127/d`)
        .then(responseHandler)
    }

    client.increaseContrast = function() {
        fetch(`${url}/api/keycode/${parseInt(SPECIAL_BASE + parseInt("0x006A"))}/d`)
        .then(responseHandler)
    }

    client.decreaseContrast = function() {
        fetch(`${url}/api/keycode/${parseInt(SPECIAL_BASE + parseInt("0x006B"))}/d`)
        .then(responseHandler)
    }

    client.increaseBrightness = function() {
        fetch(`${url}/api/keycode/${parseInt(SPECIAL_BASE + parseInt("0x0068"))}/d`)
        .then(responseHandler)
    }

    client.decreaseBrightness = function() {
        fetch(`${url}/api/keycode/${parseInt(SPECIAL_BASE + parseInt("0x0069"))}/d`)
        .then(responseHandler)
    }

    client.cycleLayoutRight = function() {
        fetch(`${url}/api/keycode/62/d`)
        .then(responseHandler)
    }

    client.cycleLayoutLeft = function() {
        fetch(`${url}/api/keycode/60/d`)
        .then(responseHandler)
    }

    client.setBandwidth = function(band) {
        fetch(`${url}/api/setBandwidth/${band}`)
        .then(responseHandler)
    }

    client.setLowLatencyMode = function(band) {
        fetch(`${url}}/api/setPlanner/0`)
        .then(responseHandler)
        fetch(`${url}/api/setVideoReorderBufferSize/0`)
        .then(responseHandler)
    }

    client.setHighLatencyMode = function(band) {
        fetch(`${url}/api/setPlanner/3`)
        .then(responseHandler)
        fetch(`${url}/api/setVideoReorderBufferSize/2`)
        .then(responseHandler)
    }

    client.echoOneWay = async function() {
        const res  = await fetch(`http://${url}:8090`)
        return Date.now() - parseInt(res.headers.get("Expires"))
    }

    client.echoTwoWay = async function(){
        try {
            const start = Date.now()
            const response  = await fetch(`${url}/echo/`)
            if(response.status != 200) {
                console.log(`Error response from rest api ${response}`)
                return -1
            }
            return Date.now() - start;
        }
        catch(e) {
            return -1
        }
    }

    return client
}

export default NodeApi;
