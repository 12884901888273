import { useEffect} from "react"

function monitorController(gamepad) {
    var axes = gamepad.axes.map(ax => parseInt(2 ** 15 * ax))
    if (axes.length < 8) {
        axes = axes.concat(new Array(8 - axes.length).fill(0))
    }
    var cleanButtons = gamepad.buttons.map(bt => bt.pressed ? 1 : 0);
    if (cleanButtons.length < 16) {
        cleanButtons = cleanButtons.concat(new Array(16 - cleanButtons.length).fill(0))
    }
    var buttons = 0
    for (var b in cleanButtons) {
        if (cleanButtons[b])
            buttons = buttons | (1 << b);
    }
    return { type: "control", state: {axes: axes, buttons: buttons} };
}

export default function useGamepadSocketApi(gamepad, websocketSend) {
    useEffect(() => {
        if (gamepad === null || !websocketSend) {
            return;
        }
        const intervalRef = setInterval(() => {
            const payload = monitorController(navigator.getGamepads()[gamepad]);
            websocketSend.send(JSON.stringify(payload));
        }, 100);
        return () => {
            clearInterval(intervalRef);
        }
    }, [gamepad, websocketSend])
}
