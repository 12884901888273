import React from 'react';

const styles = {
    loading: {
        position: 'fixed',
        top: "50%",
        left:"48%"
    },
    video: {
        objectFit: 'contain',
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0
    }
}

function Stream({ stream }) {
    if (!stream) {
        return null;
    }
    return <div>
        <video style={styles.video} key={stream.id} id={"remotevideo" + stream.id} playsInline autoPlay muted></video>
    </div>;
};

export default Stream;
