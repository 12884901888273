import React from 'react';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import useGamepad from '../../hooks/useGamepad';

function StreamButtons({ readonly, websocket }) {
    const gamepad = useGamepad();
    const controllerConnectedTooltip = `Controller ${gamepad !== null ? 'Connected' : 'Disconnected'}`
    const socketConnectedTooltip = `Socket ${gamepad !== null ? 'Connected' : 'Disconnected'}`
    if (readonly) {
        return null
    }
    return (
        <div style={{
            position: 'fixed',
            top: 50,
            left: 50
        }}>
            <div style={{ zIndex:999, color:"white", width: '100%', paddingLeft: 30 }}>
                <Stack spacing={4}>
                    {!readonly && <Tooltip title={controllerConnectedTooltip}>
                        <Fab variant="circular" color={gamepad !== null ? 'primary' : 'default'}>
                            <SportsEsportsIcon />
                        </Fab>
                    </Tooltip>}
                    <Tooltip title={socketConnectedTooltip}>
                        <Fab variant="circular" color={websocket ? 'primary' : 'default'}>
                            <WifiTetheringIcon />
                        </Fab>
                    </Tooltip>
                </Stack>
            </div>
        </div>
    );
};

export default StreamButtons;
