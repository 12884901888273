import React from 'react';
import Button from '@mui/material/Button';

//<RoundedButton text="Login" handleClick={handleLogin}/>
const RoundedButton = (props) => {
    const settings = {
        height:50,
        borderRadius: 50,
        width:180,
        "background-image": "linear-gradient(135deg, #ff592c, #d66ee0)",
        position: 'relative'
    }
    return (
        <Button variant='contained' sx={ settings }  onClick={props.onClick} {...props} >{props.text}</Button>
    );
};
export default RoundedButton;
