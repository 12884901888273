import Tooltip from "@mui/material/Tooltip";
import Stack from '@mui/material/Stack';
import { AppBar,createTheme, ThemeProvider, Toolbar, Typography }from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import RefreshIcon from '@mui/icons-material/Refresh';

const theme = createTheme({
    palette: {
        secondary: {
            main: '#ffffff',
        },
    },
});

export default function TopBar({ refreshVehicles }) {
    const { logout } = useAuth0();
    return (
        <AppBar style={{position:"relative"}}>
            <Toolbar>
                <Stack justifyContent="space-between" style={{minWidth:"100%"}} direction={"row"}>
                    <Typography style={{paddingTop: 9}} variant="h5">
                        Drive-U FMS
                    </Typography>
                    <ThemeProvider theme={theme}>
                        <Stack direction={"row"} spacing={2}>
                            <Tooltip title="Refresh">
                                <IconButton onClick={refreshVehicles} color="secondary">
                                    <RefreshIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Logout">
                                <IconButton onClick={() => logout({ returnTo: window.location.origin })} color="secondary">
                                    <LogoutIcon/>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </ThemeProvider>
                </Stack>
            </Toolbar>
        </AppBar>
    );
}
