import React, {useState, useEffect} from "react";
import {useHistory, useParams, useLocation } from 'react-router-dom';
import Client from "../../janus/client.js"
import Stream from './Stream';
// import OtherConnectionDetectedDialog from '../OtherConnectionDetectedDialog';
import StreamInfo from "./StreamInfo.js";
import StreamButtons from "./StreamButtons.js";
import StreamStatus from "./StreamStatus.js";
import OperatePage from "../OperatePage.js";
import useVehicles from "../../hooks/useVehicles";
import useGamepad from "../../hooks/useGamepad";
import useGamepadSocketApi from "../../hooks/useGamepadSocketApi";
import playVideo from "../../api/playVideo";
import pauseVideo from "../../api/pauseVideo";
import closeNode from "../../api/closeNode";
import { parse } from 'query-string';
function waitForReset() {
    return new Promise((resolve => setTimeout(resolve, 2500)));
}

function Drive({ readonly = false }) {
    // const history = useHistory();
    const gamepad = useGamepad();
    const [stream, setStream] = useState(null);
    const [janusConnected, setJanusConnected] = useState(null);
    const [metaData, setMetadata] = useState({})
    const [websocketSend, setWebsocketSend] = useState(null)
    const [websocketState, setWebsocketState] = useState(null)
    // const [openOtherConnectionDetectedDialog, setOpenOtherConnectionDetectedDialog] = useState(false)
    const [playingAge, setPlayingAge] = useState(null)
    const vehicles = useVehicles()
    const { vehicleId } = useParams()
    const { search } = useLocation();
    const { node_socket_url, node_url, rtc_url } = parse(search)
    useGamepadSocketApi(gamepad, websocketSend);


    useEffect(() => {
        if (!node_socket_url) {
            return;
        }
        let websocket;
        async function connect() {
            if (!node_socket_url) {
                return;
            }
            try {
                await closeNode(node_url);
            } catch (e) {}

            await waitForReset();
            const fixed_node_socket_url = `wss://${node_socket_url}`
            websocket = new WebSocket(fixed_node_socket_url);
            function handleConnected(event) {
                console.log('handleConnected')
                setWebsocketSend({ send: (payload) => websocket.send(payload) });
            }
            function handleClose(event) {
                console.log('handleClose', event)
                setWebsocketSend(null);
            }
            function handleError(event) {
                console.log('handleError', event)
                setWebsocketSend(null);
            }
            function handleMessage({ data }) {
                const dataObject = JSON.parse(data);
                setMetadata(dataObject);
            }
            websocket.onopen = handleConnected;
            websocket.onclose = handleClose;
            websocket.onmessage = handleMessage;
            websocket.onerror = handleError;
            setWebsocketState(websocket)
        }
        connect()
        return () => {
            if (!websocket) {
                return;
            }
            websocket.close();
            setWebsocketSend(null)
        }
    }, [node_socket_url, setMetadata, setWebsocketSend]);

    // TBD - Take control issue fix
    // useEffect(() => {
    //     console.log(`vehicle.controlStateTimeStamp`, vehicle?.controlStateTimeStamp)
    //     if (!websocketState || readonly) {
    //         return;
    //     }
    //     websocketState.close();
    //     setWebsocketSend(null)
    //     setWebsocketState(null)
    //     history.push(`/view/${vehicleId}`)
    //     // setOpenOtherConnectionDetectedDialog(true)
    // }, [vehicle?.controlStateTimeStamp])

    useEffect(() => {
        if (!rtc_url) {
            return;
        }
        const janus = new Client(rtc_url)
        let currentStream;
        let intervalRef;
        janus.subscribe("attached", () => {
            janus.updateStreamsList()
        });
        janus.subscribe("streams", (streams) => {
            setStream(streams[0])
            currentStream = streams[0];
            janus.startStream(currentStream)
            intervalRef = setInterval(() => janus.getStreamInfo(currentStream), 2000)
        });
        janus.subscribe("stream_info", (info) => {
            setPlayingAge(info.video_age_ms);
            console.log('stream_info.video_age_ms', info.video_age_ms)
        });
        janus.startClient(() => setJanusConnected(true), () => {
            setJanusConnected(false)
        });
        return () => {
            janus.closeClient();
            if (intervalRef) {
                clearInterval(intervalRef);
            }
        }
    }, [rtc_url]);
    return (
        <OperatePage>
                {/*<OtherConnectionDetectedDialog open={openOtherConnectionDetectedDialog} />*/}
                <StreamStatus connected={janusConnected} onPauseVideo={() => pauseVideo(node_url)} onPlayVideo={() => playVideo(node_url)} />
                <Stream stream={stream} />
                <StreamInfo metaData={metaData} playingAge={playingAge} />
                <StreamButtons readonly={readonly} websocket={!!websocketSend} />
        </OperatePage>
    );
};
export default Drive;
