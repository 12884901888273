import AdminPageContent from "./AdminPageContent";
import Button from "@mui/material/Button";
import AdminPage from "./AdminPage";

export default function ErrorPage({ error, code, refresh }) {
    console.log(error);
    return (
        <AdminPage>
            <AdminPageContent
                style = {{
                    padding: '0px 10px',
                    position: 'relative',
                }}
            >
                <div style = {{  position: 'relative', top: '50%', transform: 'translateY(-50%)'}}>
                    <h1> { code } </h1>
                    <h3> { error } </h3>
                    <Button size={'medium'} onClick={refresh} > refresh </Button>
                </div>
            </AdminPageContent>
        </AdminPage>
    )
}
