import {useEffect, useState} from "react";
import GamepadContext from '../context/gamepad'

function GamepadProvider({ children }) {
    const [gamepad, setGamepad] = useState(null);

    useEffect(() => {
        function handleGamepadDisconnected(event) {
            console.log('handleGamepadDisconnected', event)
            setGamepad(null);
        }
        function handleGamepadConnected(event) {
            console.log('handleGamepadConnected', event)
            setGamepad(event.gamepad.index);
        }
        window.addEventListener("gamepadconnected", handleGamepadConnected);
        window.addEventListener("gamepaddisconnected", handleGamepadDisconnected);
        return () => {
            window.removeEventListener('gamepadconnected', handleGamepadConnected);
            window.removeEventListener('gamepaddisconnected', handleGamepadDisconnected);
        }
    }, []);

    return <GamepadContext.Provider value={gamepad}>
        {children}
    </GamepadContext.Provider>
}

export default GamepadProvider;
