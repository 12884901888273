import React from 'react';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

function getConnectionStatus(connected) {
    if (connected) {
        return 'Connected';
    }
    if (connected === false) {
        return 'Disconnected';
    }
    return 'Connecting...';
}
function StreamStatus({ onPlayVideo, onPauseVideo, connected }) {
    return (
        <div style={{
            position: 'fixed',
            top: 50,
            right: 50,
            zIndex: 9999,
        }}>
            <div style={{ zIndex:999, color:"white", paddingLeft: 30 }}>
                <Stack direction={'row'} spacing={4} alignItems={"center"}>
                    <Fab variant={'extended'} >
                        {getConnectionStatus(connected)}
                    </Fab>

                        <Tooltip title={'Pause Video'} onClick={onPauseVideo}>
                            <Fab variant="circular">
                                <PauseIcon />
                            </Fab>
                        </Tooltip>
                        <Tooltip title={'Play Video'}>
                            <Fab variant="circular" onClick={onPlayVideo}>
                                <PlayArrowIcon />
                            </Fab>
                        </Tooltip>

                </Stack>
            </div>
        </div>
    );
};

export default StreamStatus;
